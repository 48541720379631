<template>
  <div class="d-flex align-center flex-column w-100">
    <span id="stage-4" class="my-6 my-md-8 d-flex align-center w-100" auto-grow>
      <v-spacer/>
      <span class="text-h5 font-weight-bold">5. Описание</span>
      <div style="flex: 1 0 0">
        <a style="float: right; font-size: 14px" class="text-end d-md-inline d-none" @click="$emit('update:import', true)">Заполнить из...</a>
        <v-icon style="float: right;" class="d-md-none" @click="$emit('update:import', true)">mdi-dots-vertical</v-icon>
      </div>
    </span>
    <v-form
      v-model="valid"
      class="d-flex align-center flex-column w-100"
      ref="form"
    >
      <v-text-field
        v-model="value.title"
        :rules="required"
        label="Название участка*"
        item-text="name"
        return-object
        outlined
        dense
        class="w-100"
      >
      </v-text-field>
      <v-text-field
        :value="value.data.area"
        disabled
        outlined
        dense
        class="w-100"
        label="Площадь (м2)"
      ></v-text-field>
      <v-text-field
        v-model="value.data.group_contents"
        :rules="required"
        label="Формула древостоя*"
        outlined
        dense
        class="w-100"
      >
      </v-text-field>
      <v-select
        v-model="value.data.condition"
        label="Состояние"
        :items="conditions"
        return-object
        outlined
        dense
        class="w-100"
      >
      </v-select>
      <v-textarea
        v-model="value.note"
        label="Примечание"
        outlined
        dense
        counter
        maxlength="2000"
        class="w-100"
      >
      </v-textarea>
      <v-autocomplete
          v-model="value.data.responsible_team_text"
          label="Обслуживающая организация"
          :items="['МКУ «ГорЗеленСтрой»']"
          outlined
          dense
          class="w-100 mt-4"
          hide-details
      >
      </v-autocomplete>
      <v-select
        v-model="value.visible"
        label="Видимость"
        mandatory
        :items="[
          { value: true, label: 'Для всех' },
          { value: false, label: 'Для команды' }
        ]"
        item-text="label"
        item-value="value"
        outlined
        dense
        class="w-100 mt-4"
        hide-details
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
import area from "@turf/area"
const conditions = ["Хорошее", "Удовлетворительное", "Неудовлетворительное"];

export default {
  data: () => ({
    priv: false,
    conditions,
    genusRules: [v => v != null || "Обязательное поле"],
    required: [
      v => v?.length || "Значение не должно быть пустым"
    ],
    ageRules: [v => v != null || "Обязательное поле"],
    valid: false
  }),
  methods: {
    setStage(st) {
      this.$emit("update:stage", st);
    },
    validate() {
      this.$refs.form.validate();
    },
    blur() {
      window.document.activeElement.blur();
    }
  },
  computed: {
    allowedToProceed() {
      return this.$refs.form.value;
    },
  },
  watch: {
    valid(val) {
      this.$emit("unlock", val);
    }
  },
  mounted() {
    this.value.data.area = Math.floor(area(this.value.geometry) * 100)/100
  },
  props: {
    stage: {
      type: Number
    },
    value: {
      type: Object
    }
  },
  components: {
    // TextButton
  }
};
</script>

<style></style>
