<template>
  <div class="wizard-search-field" style="width: 100%">
    <v-menu
      content-class="wizard-search-field__results"
      v-model="showResults"
      offset-y
      max-height="400"
    >
      <template v-slot:activator="{}">
        <v-text-field
          v-model="query"
          dense
          hide-details
          class="mt-2"
          height="48"
          clearable
          outlined
          prepend-inner-icon="mdi-magnify"
          @input="showResults = query.length > 2"
          @focus="showResults = query.length > 2"
          :placeholder="'Найти...'"
        >
        </v-text-field>
      </template>
      <div class="pb-4">
        <div class="px-4 pt-2" v-if="!tree.length">
          Ничего не найдено
        </div>
        <template v-for="category in tree">
          <div
            @click="selectCategory(category.id)"
            :key="category.id"
            class="category font-weight-medium text-h6"
            v-text="category.title"
          />
          <div
            @click="selectTopic(topic)"
            v-for="topic in category.children"
            :key="category.id + 't' + topic.id"
            class="topic"
            v-text="topic.title"
          />
        </template>
      </div>
    </v-menu>
  </div>
</template>

<script>
import useClassifier from "@/mixins/useClassifier";
import categories from "@/assets/categories";

export default {
  data: () => ({
    showResults: false,
    query: ""
  }),
  mixins: [useClassifier],
  computed: {
    classifier() {
      return categories;
      // return [ ...this.OCClassifier ];
    },
    tree() {
      let tree = [];
      for (let category of this.classifier) {
        let node = { id: category.id, title: category.title, children: [], disabled: false };
        // if (category.)
        let toPush = node.title.toLowerCase().includes(this.query.toLowerCase()) && category.topics.some(el => el.disabled === false);
        for (let topic of category.topics) {
          if (topic.disabled !== false) continue;
          if (topic.title.toLowerCase().includes(this.query.toLowerCase())) {
            toPush = true;
            node.children.push({ id: topic.id, title: topic.title });
          }
        }
        if (toPush) tree.push(node);
      }
      return tree;
    }
  },
  methods: {
    selectCategory(val) {
      this.$emit("category", val);
    },
    selectTopic(val) {
      this.$emit("topic", val);
    }
  }
};
</script>

<style lang="scss">
.wizard-search-field {
  .v-input__prepend-inner,
  .v-input__append-inner {
    margin-top: 12px !important;
  }
}
.wizard-search-field__results {
  box-shadow: none !important;
  border: thin solid black;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  background: white;
  .topic {
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      background: #f6f6f6;
      color: #0682be;
    }
  }
  .category {
    padding: 0px 16px;
    margin-top: 8px;
    cursor: pointer;
    &:hover {
      // background: #f6f6f6;
      color: #0682be;
    }
  }
}
</style>
