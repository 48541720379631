<template>
  <div class="d-flex flex-column align-center w-100">
    <span id="stage-1" class="text-h5 font-weight-bold my-6 my-md-8"
      >2. Тип объекта</span
    >
    <div class="mb-8" style="width: 100%">
      <span>Поиск типа объекта</span>
      <search-field-mock @category="setStage(1)" @topic="setStage(2)" />
    </div>
    <v-container class="pa-0 mx-n3" style="width: calc(100% + 24px)">
      <template v-for="(l, i) in topics">
        <div
          :key="l.id"
          class="d-flex py-4 align-center type-button"
          :class="{ 'type-button_disabled': l.disabled }"
          @click="l.disabled || setTopic(l)"
        >
          <div class="type-button-link d-flex align-center flex-grow-1">
            <span class="medium-font font-weight-medium" v-text="l.title" />
            <v-spacer />
            <v-icon
              size="28"
              class="mr-4 type-button-doubt flex-shrink-0"
              @click.stop="dialog_details = true"
              >mdi-help-circle-outline</v-icon
            >
            <!-- <v-icon size="24" class="type-button-arrow flex-shrink-0">mdi-arrow-right</v-icon> -->
          </div>
        </div>
        <v-divider class="mx-3" :key="'div' + i" />
      </template>
    </v-container>
    <category-details-dialog v-model="dialog_details" />
  </div>
</template>

<script>
import categories from "@/assets/categories";
import CategoryDetailsDialog from "./CategoryDetailsDialog.vue";
import SearchFieldMock from "./SearchFieldMock.vue";

const topics = categories[0].topics;

export default {
  components: { SearchFieldMock, CategoryDetailsDialog },
  props: {
    stage: {
      type: Number
    },
    value: Object
  },
  data() {
    return {
      topics,
      dialog_details: false
    };
  },
  methods: {
    setTopic(topic) {
      if (this.value.objectType?.geometry !== topic.geometry) {
        this.value.geometry = null;
      }
      this.value.objectType = topic;
      this.setStage(2);
    },
    setStage(st) {
      this.$emit("update:stage", st);
    }
  }
};
</script>

<style lang="scss" scoped>
.type-button {
  padding: 0 12px;
  &.type-button_disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .type-button-arrow {
    visibility: hidden;
  }
  .type-button-link {
    transition: 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  &:hover:not(.type-button_disabled) {
    background: #f4f4f4;
    .type-button-link {
      color: #72bf44;
    }
    .type-button-arrow {
      visibility: visible;
    }
  }
  cursor: pointer;
  .type-button-doubt {
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
