<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    content-class="dialog"
    width="400"
  >
    <v-sheet tile>
      <div class="d-flex">
        <div class="px-4 px-md-6 pb-4 pt-4 text-h5 text-center font-weight-bold">
          Новый шаблон
        </div>
        <v-spacer />
        <v-btn class="ma-4 mb-0" icon @click="$emit('input', false)">
          <v-icon size="24">$close</v-icon>
        </v-btn>
      </div>
      <div class="px-4 px-md-6 pb-4 pt-4">

        <v-textarea rows="2" counter="150" v-model="title" label="Название" outlined dense></v-textarea>
        <v-select v-model="visibility" label="Видимость" outlined dense item-value="value" item-text="title" :items="[{title: 'Только я', value: 'private'}, {title: 'Команда', value: 'public'},]"></v-select>
        <div
          class="mt-0 mb-2 d-flex justify-space-between"
        >
          <v-spacer />
          <VBtn @click="saveAndExit()" depressed color="primary" :loading="loading">
            Сохранить
          </VBtn>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import TextButton from "./TextButton.vue";
import opencity from "@/service/opencity";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      title: "",
      visibility: "private",
      loading: false
    };
  },
  methods: {
    async saveAndExit() {
      await this.save();
      this.$emit('input', false);
    },
    async save() {
      await opencity.post("/templates", {
        title: this.title,
        visibility: this.visibility,
        objectType: this.object.objectType,
        data: JSON.stringify(this.object.data),
        type: "t"
      });
    }
  },
  components: {
    TextButton
  }
};
</script>

<style></style>
