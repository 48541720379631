<template>
  <div class="create-issue__map-step d-flex align-center flex-column w-100">
    <span id="stage-3" class="text-h5 font-weight-bold mb-4 mt-4"
      >4. Где находится объект?</span
    >
    <template v-if="photoCoordOptions && editingType === 'Point'">
      <a class="mb-3 mt-n3" v-if="photoCoordOptions.length === 1" @click="setCoordToMedia(photoCoordOptions[0])">
        Координаты из фото
      </a>
      <v-menu content-class="rounded-lg" offset-y v-else-if="photoCoordOptions.length > 1">
        <template #activator="{ on }">
          <a class="mb-3 mt-n3" v-on="on">
            Координаты из фото
            <v-icon class="ml-n" style="color: inherit" size="18">mdi-chevron-down</v-icon>
          </a>
        </template>
        <v-sheet class="rounded-lg pa-2">
          <img tabindex="0" v-for="media in photoCoordOptions" :key="media.id" @click="setCoordToMedia(media)" :src="media.url" style="width: 76px; height: 76px; border-radius: 8px; margin: 4px; object-fit: cover; cursor: pointer">
        </v-sheet>
      </v-menu>
    </template>
    <span class="pb-3 w-100" v-text="introduction" />
    <div class="map-wrapper-wrapper">
      <OCMap ref="map" @update:draw="onDrawUpdate" :draw="draw" :options="mapOptions">
        <template #controls-top>
          <div class="mx-6"></div>
          <v-spacer />
          <OCMapLocationSearch />
          <v-spacer />
          <OCMapSettings>
            <template #prepend>
              <v-list-item @click="freePin = !freePin" v-if="editingType === 'Point'">
                <v-list-item-content>
                  Свободная метка
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon v-show="freePin">mdi-check</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="showAll = !showAll">
                <v-list-item-content>
                  Объекты
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon v-show="showAll">mdi-check</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                v-if="editingType === 'Point'"
                @click="showCoords = !showCoords"
              >
                <v-list-item-content>
                  Координаты
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon v-show="showCoords">mdi-check</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider/>
            </template>
          </OCMapSettings>
        </template>
        <template #controls-right>
          <v-spacer />
          <OCMapGeolocate />
          <OCMapZoom />
          <div class="my-3" />
        </template>
        <template #controls-bottom>
          <v-spacer />
          <div
            class="ma-auto d-flex justify-center mb-2"
            v-if="showCoords"
            style="max-width: 16rem"
          >
            <v-text-field
              label="Широта"
              v-model="lat"
              class="shrink mr-1"
              solo
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              label="Долгота"
              v-model="lng"
              class="shrink ml-1"
              solo
              dense
              hide-details
            ></v-text-field>
          </div>
          <v-spacer />
        </template>
      </OCMap>
    </div>

    <label class="w-100 mt-4">
      Расположение (ближайший адрес):
      <v-text-field
        hint="При необходимости уточните значение вручную"
        persistent-hint
        outlined
        dense
        :loading="!!(replaceLocation && timeout)"
        v-model="value.location"
      ></v-text-field>
    </label>
    <v-checkbox
      v-model="replaceLocation"
      class="mt-0 w-100"
      v-if="editingType === 'Point'"
      label="Определять адрес автоматически"
    />
    <p class="w-100" v-for="object in parentObjects" :key="object.id">
      Входит в состав
      <router-link :to="'/service/objects/' + object.id"><b v-text="object.title"/></router-link>
    </p>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import opencity from "../../../service/opencity";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import features from "@/utils/features";
import OCMap from "@/components/map/OCMap";
import OCMapLocationSearch from "@/components/map/OCMapLocationSearch.vue";
import OCMapSettings from "@/components/map/OCMapSettings";
import OCMapZoom from "@/components/map/OCMapZoom";
import OCMapGeolocate from "@/components/map/OCMapGeolocate";


const SUGGESTIONS_DEBOUNCE = 300;
export default {
  props: {
    stage: {
      type: Number
    },
    value: {
      type: Object
    }
  },
  data: () => ({
    draw: null,
    coords: { lng: 56.169770480103284, lat: 58.01933146095297 },
    zoom: 12,
    markerLatLng: null,
    showAll: true,
    showCoords: false,
    mapContainer: null,
    parentObjects: [],
    parentObjectsLoading: false,
    replaceLocation: true,
    timeout: undefined
  }),
  methods: {
    setCoordToMedia(media) {
      this.lat = media.coords.lat;
      this.lng = media.coords.lon;
    },
    async positionMap() {
      const map = this.$refs.map;
      const lastLocation = this.getLastLocation();
      if (this.value.geometry) {
        map.focusItem(this.value, { animate: false });
      } else if (lastLocation) {
        let a = await map.getMap();
        a.panTo([lastLocation.lng, lastLocation.lat], { zoom: lastLocation.zoom, animate: false });
      } else if (navigator.geolocation) {
        map.jumpToUserLocation({ animate: false });
      }
    },
    getLastLocation() {
      const lastLocation = localStorage.getItem("lastLocation");
      if (lastLocation) {
        const [lng, lat, zoom] = lastLocation.split(",").map(parseFloat);
        return { lng, lat, zoom };
      }
      return null;
    },
    setLastLocation(location, zoom) {
      localStorage.setItem(
        "lastLocation",
        `${location.lng},${location.lat},${
            zoom
        }`
      );
    },
    setStage(st) {
      this.$emit("update:stage", st);
    },
    async showPopup(id, map, point) {
      const obj = await opencity.getObject(id);

      const template = `<a href="/service/objects/${id}" target="_blank">${obj.title ||
        obj.data.genus?.name}</a>`;

      new mapboxgl.Popup()
        .setLngLat(
          obj.geometry.type === "Point" ? obj.geometry.coordinates : point
        )
        .setHTML(template)
        .addTo(map);
    },
    onDrawUpdate(val) {
      this.value.geometry = val.geometry;
      if (this.value.geometry.type === "Point") {
        const latLng = {
          lat: val.geometry.coordinates[1],
          lng: val.geometry.coordinates[0]
        };
        latLng.lat = Math.round(latLng.lat * 1000000) / 1000000;
        latLng.lng = Math.round(latLng.lng * 1000000) / 1000000;
        this.value.geometry.coordinates = [latLng.lng, latLng.lat];
        if (this.replaceLocation) this.fetchSuggestions(latLng.lat, latLng.lng);
        opencity
          .probeObjects(latLng.lat, latLng.lng)
          .then(res => {this.parentObjects = res});
      } else {
        this.parentObjectsLoading = true;
        opencity
          .probeIntersectingObjects(this.value.geometry)
          .then(el => {
            const parentObjects = el.filter(
              el =>
                el.objectType.id === 2 ||
                el.objectType.id === 3 ||
                el.objectType.id === 4
            );
            this.parentObjects = parentObjects.length ? [parentObjects[0]] : [];
          })
          .finally(() => (this.parentObjectsLoading = false));
      }
    },
    async fetchSuggestions(lat, lng) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const data = await fetch(
          `https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Token " + process.env.VUE_APP_DADATA_KEY
            },
            body: JSON.stringify({ lat: lat, lon: lng, count: 1 })
          }
        ).then(response => response.json());
        this.timeout = undefined;
        this.value.location = data?.suggestions[0].value.replace("г Пермь, ", "");
      }, SUGGESTIONS_DEBOUNCE);
    },
  },
  mounted() {
    this.positionMap();
    this.draw = {
      config: {
        type: this.editingType === "Point" ? "Point" : "Polygon",
        edit: true,
        bound: true
      },
      geometry: this.value?.geometry
    };
    if (this.editingType === "Point") {
      const wgs84 = this.value?.geometry?.coordinates;
      if (wgs84 != null) {
        this.markerLatLng = {
          lat: wgs84[1],
          lng: wgs84[0]
        };
        this.coords = [...wgs84];
      }
    }
    this.$emit("unlock", this.allowedToProceed);
  },
  beforeDestroy() {
    let coords = this.$refs.map?.$refs.map?.map?.getCenter();
    let zoom = this.$refs.map?.$refs.map?.map?.getZoom();
    this.setLastLocation(coords, zoom);
  },
  computed: {
    photoCoordOptions() {
      return this.value.media?.filter(el => el.coords) ?? [];
    },
    freePin: {
      get() {
        return !this.draw.config.bound;
      },
      set(val) {
        this.draw.config.bound = !val;
      }
    },
    mapOptions() {
      return {
        hideFeatures: !this.showAll,
        showUnmoderated: true
      };
    },
    editingType() {
      return this.value.objectType.geometry;
    },
    introduction() {
      if (this.editingType === "Polygon") {
        return `Нарисуйте фигуру (полигон). Для перехода к нужному адресу воспользуйтесь поиском.`;
      } else if (this.editingType === "Point") {
        return `Укажите точку на карте. Для перехода к нужному адресу воспользуйтесь поиском.`;
      } else {
        return `Неизвестный тип геометрии`;
      }
    },
    allowedToProceed() {
      return this.value.geometry != null;
    },
    lat: {
      get() {
        const { geometry } = this.value;
        return (geometry?.type === "Point" && geometry.coordinates[1]) || null;
      },
      set(val) {
        const { geometry } = this.value;
        if (geometry?.type !== "Point") return;
        geometry.coordinates = [geometry.coordinates[0], val];
        this.markerLatLng = { lat: val, lng: this.lng ?? 0 };
        this.$refs.map?.updatePoint(val, this.lng ?? 0);
      }
    },
    lng: {
      get() {
        const { geometry } = this.value;
        return (geometry?.type === "Point" && geometry.coordinates[0]) || null;
      },
      set(val) {
        const { geometry } = this.value;
        if (geometry?.type !== "Point") return;
        geometry.coordinates = [val, geometry.coordinates[1]];
        this.markerLatLng = { lat: this.lat ?? 0, lng: val };
        this.$refs.map?.updatePoint(this.lat ?? 0, val);
      }
    }
  },
  watch: {
    markerLatLng(val) {
      this.parentObjectsLoading = false;
      opencity
        .probeObjects(val.lat, val.lng)
        .then(el => {
          this.parentObjects = el;
        })
        .finally(() => (this.parentObjectsLoading = false));
      this.value.geometry = {
        type: "Point",
        coordinates: [val.lng, val.lat]
      };
    },
    allowedToProceed: {
      handler(val) {
        this.$emit("unlock", val);
      }
    },
  },
  components: {
    OCMapGeolocate,
    OCMapZoom,
    OCMapSettings,
    OCMapLocationSearch,
    OCMap
  }
};
</script>

<style lang="scss">
.mapboxgl-ctrl-attrib-inner a[title="Improve this map"] {
  display: none;
}

.map-wrapper-wrapper {
  width: calc(100% + 32px);
  position: relative;
  height: 400px;
  margin: 0 -16px;
  max-width: 100vw;
  @media (min-width: 960px) {
    height: 460px;
    width: calc(100% + 64px);
    margin: 8px -32px;
  }
  .mapboxgl-ctrl-top-right {
    top: 72px;
  }
  .mapboxgl-ctrl-group button {
    width: 40px;
    height: 40px;
  }
}

.create-issue__map-step .mgl-map-wrapper {
  height: 100%;
  width: 100%;
}

.autocomplete-results {
  background: white;
}

.click-through-container {
  pointer-events: none;
  & * {
    pointer-events: initial;
  }
}
</style>
