import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"content-class":"dialog","width":"400"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c(VSheet,{attrs:{"tile":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"px-4 px-md-6 pb-4 pt-4 text-h5 text-center font-weight-bold"},[_vm._v(" Новый шаблон ")]),_c(VSpacer),_c(VBtn,{staticClass:"ma-4 mb-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c(VIcon,{attrs:{"size":"24"}},[_vm._v("$close")])],1)],1),_c('div',{staticClass:"px-4 px-md-6 pb-4 pt-4"},[_c(VTextarea,{attrs:{"rows":"2","counter":"150","label":"Название","outlined":"","dense":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c(VSelect,{attrs:{"label":"Видимость","outlined":"","dense":"","item-value":"value","item-text":"title","items":[{title: 'Только я', value: 'private'}, {title: 'Команда', value: 'public'} ]},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}}),_c('div',{staticClass:"mt-0 mb-2 d-flex justify-space-between"},[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.saveAndExit()}}},[_vm._v(" Сохранить ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }