<template>
  <v-container class="">
    <div :dense="!$vuetify.breakpoint.mdAndUp" :class="parentClass">
      <div v-for="(file, index) in files" :key="file.id" :class="childClass">
        <v-menu absolute>
          <template v-slot:activator="{ on }">
            <div class="upload-thumbnail rounded-lg overflow-hidden" :style="file.error ? 'outline: solid red 2px;' : ''">
              <v-img class="rounded-lg" v-on="on" aspect-ratio="1" :src="file.url"/>
              <div v-if="file.coords" style="position: absolute; bottom: 0; right: 0; padding: 4px; background: rgba(0,0,0,0.6); pointer-events: none" class="rounded-tl-lg">
                <v-icon size="18" color="white">mdi-map-marker-outline</v-icon>
              </div>
              <div class="progress-circular rounded-lg" v-show="file.uploading">
                <v-progress-circular
                    style="width: 100%; height: 100%;"
                    :width="2"
                    color="white"
                    :value="file.progress * 100"
                />
              </div>
              <div v-if="file.error" class="progress-circular rounded-lg d-flex align-center justify-center">
                <v-icon x-large color="red">
                  mdi-alert-octagon-outline
                </v-icon>
              </div>
            </div>
          </template>
          <v-list>
            <template v-if="file.error">
              <v-list-item @click="uploadFile(file)">Загрузить еще раз</v-list-item>
              <v-divider class="my-1" />
            </template>
            <div class="px-2">
              <div class="px-2 text-body-2">Порядок</div>
              <v-btn large :disabled="index === 0" icon @click="moveTo(file.id, 0)" class="rounded-0">
                <v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
              <v-btn large :disabled="index === 0" icon @click="moveTo(file.id, index - 1)" class="rounded-0">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn large :disabled="index === files.length - 1" icon @click="moveTo(file.id, index + 1)" class="rounded-0">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn large :disabled="index === files.length - 1" icon @click="moveTo(file.id, files.length - 1)" class="rounded-0">
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
            <v-divider class="my-1"/>
            <v-list-item @click="openGallery(file.id)">Открыть</v-list-item>
            <v-list-item @click="removeItem(file.id)">Удалить</v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-col :class="childClass">
        <v-responsive :aspect-ratio="1">
          <label for="files">
            <div class="pa-1 drop-area d-flex flex-column justify-center align-center" style="height: 100%">
              <v-icon size="32" style="opacity: 0.5">mdi-camera-plus</v-icon>
              <span class="text-center text-sm-body-1 text-body-2 mt-md-1 ">Добавить фото</span>
            </div>
          </label>
        </v-responsive>
      </v-col>
    </div>
    <input
        accept="image/png, image/jpeg"
        type="file"
        multiple
        style="display: none;"
        id="files"
        @change="onInputChange"
    />
  </v-container>
</template>
<script>
import PhotoSwipe from "photoswipe";
import "photoswipe/dist/photoswipe.css";
import opencity from "@/service/opencity";

let seqId = 0;

export default {
  name: 'MediaUploadGallery',
  props: {
    value: {},
    parentClass: {
      default: "row"
    },
    childClass: {
      default: "col col-4 col-sm-3"
    }
  },
  computed: {
    files: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    output() {
      return this.files.map(el => ({
        uploading: el.upload,
        error: el.error,
        url: el.url,
        id: el.id,
        realId: el.realId
      }));
    }
  },
  watch: {
    output: {
      handler() {
        this.$emit('input', this.files);
      },
      immediate: true
    }
  },
  methods: {
    retryAll() {
      for (let file of this.files) {
        if (file.error) {
          this.uploadFile(file);
        }
      }
    },
    openGallery(fileId) {
      const dataSource = this.files.map(el => ({
        src: el.url
      }));

      const index = this.files.findIndex(el => el.id == fileId);
      const photoSwipe = new PhotoSwipe({
        index,
        dataSource,
        wheelToZoom: true
      });
      photoSwipe.init();
    },
    moveTo(fileId, position) {
      const index = this.files.findIndex(el => el.id == fileId);
      if (position < 0 || position >= this.files.length) return;
      if (index === position) return;
      const tmp = this.files[index];
      this.files.splice(index, 1);
      this.files.splice(position, 0, tmp);
    },
    removeItem(fileId) {
      const index = this.getFileIndex(fileId);
      if (index != -1) {
        const file = this.files.splice(index, 1);
        if (file.xhr.readyState != 4) {
          file.xhr.abort();
        }
      }
    },
    getFileIndex(fileId) {
      return this.files.findIndex(el => el.id == fileId);
    },
    onDrop(ev) {
      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          const dataTransferItem = ev.dataTransfer.items[i];
          if (
              dataTransferItem.kind === "file" &&
              (dataTransferItem.type === "image/png" ||
                  dataTransferItem.type === "image/jpeg")
          ) {
            let file = ev.dataTransfer.items[i].getAsFile();
            this.addFile(file);
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          let file = ev.dataTransfer.files[i];
          this.addFile(file);
        }
      }
    },
    onInputChange(ev) {
      for (let i = 0; i < ev.target.files.length; i++) {
        this.addFile(ev.target.files[i]);
      }
    },
    addFile(file) {
      const reader = new FileReader();
      let id = seqId++;
      const obj = {
        file,
        url: null,
        id,
        uploading: false,
        error: false,
        progress: 0,
        xhr: null,
        realId: null,
        coords: null
      };
      reader.onload = () => {
        obj.url = reader.result;
      };
      reader.readAsDataURL(file);
      this.files.push(obj);
      this.uploadFile(obj);
    },
    async uploadFile(file) {
      file.error = false;
      file.progress = 0;
      file.uploading = true;

      const xhr = new XMLHttpRequest();
      var formData = new FormData();
      formData.append("file", file.file);
      file.xhr = xhr;

      const success = await new Promise(resolve => {
        xhr.upload.addEventListener("progress", event => {
          console.log("PROGRESS", event)
          if (event.lengthComputable) {
            file.progress = event.loaded / event.total;
            // console.log("upload progress:", file.progress);
          }
        });
        xhr.addEventListener("loadend", () => {
          if (xhr.status === 401) {
            opencity._refresh().then(() => {
              this.uploadFile(file);
            });
            return;
          }
          if (xhr.status === 200) {
            let json = JSON.parse(xhr.responseText);
            file.realId = json.fileName;
            file.coords = json.originalMetadata?.['oc:coordinates'];
            resolve(true)
          } else {
            resolve(false);
          }
        });
        xhr.addEventListener("error", () => {
          resolve(false);
        });
        xhr.open("POST", opencity.API_BASE + "/files/", true);
        xhr.setRequestHeader(
            "Authorization",
            opencity.AUTHORIZATION_PREFIX + opencity.access_token
        );
        xhr.setRequestHeader("Response-Type", "application/json");
        xhr.send(formData);
      });

      file.uploading = false;
      if (!success) {
        file.error = xhr.status || true;
      }
    },
  }
}
</script>
<style lang="scss" scoped>

.drop-area {
  border: 2px dashed #26323899;
  //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%230000009C' stroke-width='4' stroke-dasharray='2%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    border-color: #263238bb;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.upload-thumbnail {
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .progress-circular {
    position: absolute;
    padding: 12px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.26666666666666666);
    pointer-events: none;
  }
}
</style>