<script>
import AddIssueDescriptionStepTree from "@/views/Objects/NewObject/NewObjectParamsStepTree.vue";
import AddIssueDescriptionStepTGroup from "@/views/Objects/NewObject/NewObjectParamsStepTGroup.vue";
import AddIssueDescriptionStepBGroup from "@/views/Objects/NewObject/NewObjectParamsStepBushGroup.vue";
import AddIssueDescriptionStepBush from "@/views/Objects/NewObject/NewObjectParamsStepBush.vue";

export default {
  functional: true,
  render(createElement, context) {
    const typeId = context.props.value.objectType.id;
    if (typeId === 1) {
      return createElement(AddIssueDescriptionStepTree, context.data, context.children);
    }
    if (typeId === 14) {
      return createElement(AddIssueDescriptionStepBush, context.data, context.children);
    }
    if (typeId === 51) {
      return createElement(AddIssueDescriptionStepBGroup, context.data, context.children);
    }
    return createElement(AddIssueDescriptionStepTGroup, context.data, context.children);
  }
};
</script>

<style></style>
