<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    content-class="dialog"
    width="400"
  >
    <v-sheet tile>
      <div class="d-flex">
        <div class="px-4 px-md-6 pb-4 pt-4 text-h5 text-center font-weight-bold">
          Выбрать шаблон
        </div>
        <v-spacer />
        <v-btn class="ma-4 mb-0" icon @click="$emit('input', false)">
          <v-icon size="24">$close</v-icon>
        </v-btn>
      </div>
      <v-divider/>
      <div class="px-2 overflow-y-auto d-flex flex-column" style="height: min(380px, 70vh)">
        <v-list dense>
          <v-list-item v-for="template in templates" :key="template.id" @click="selectAndExit(template)">
            <v-list-item-content>
              <v-list-item-title style="-webkit-line-clamp: 2; -webkit-box-orient: vertical; display: -webkit-box; white-space: initial">{{template.title}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu>
                <template #activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list dense>
                  <v-list-item @click="deleteTemplate(template)">
                    <v-list-item-title>Удалить</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <span v-if="!loading && templates.length === 0" class="text-center d-block w-100">Нет шаблонов</span>
        </v-list>
        <v-spacer/>
        <v-list dense style="position: sticky; bottom: 0; justify-self: end">
          <v-divider/>
          <v-list-item>
            <v-list-item-title>
              <v-icon class="mr-1 mb-0">mdi-import</v-icon>
              Скопировать из объекта
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import opencity from "@/service/opencity";
import {dateformat2} from "@/utils/datetime";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      title: "",
      visibility: "private",
      loading: true,
      templates: []
    };
  },
  methods: {
    selectAndExit(template) {
      this.object.data = JSON.parse(template.data);
      this.$emit('input', false);
    },
    dateformat2(date) {
      return dateformat2(date)
    },
    reload() {
      this.loading = true;
      opencity.get("/templates").then((res) => res.json()).then((res) => {
        this.templates = res.filter(el => el.objectType.id == this.object.objectType?.id);
        this.loading = false;
      });
    },
    deleteTemplate(template) {
      opencity.delete("/templates/" +template.id).then(() => this.reload());
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.reload();
      }
    }
  },
  components: {
  }
};
</script>

<style></style>
