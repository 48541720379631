<template>
  <div class="demo-issue-stepper">
    <transition
      :duration="{ enter: 60, leave: 60 }"
      name="fade-transition"
      mode="out-in"
    >
      <div class="center-container d-flex flex-column" v-if="!newObjId">
        <div class="d-flex align-center py-4 pt-md-8 pb-md-4 demo-scroll">
          <span
            class="text-uppercase font-weight-medium"
            style="opacity: 0.5; font-size: 14px"
            >Новый объект</span
          >
          <v-spacer />
          <TextButton
            icon
            color="#414249"
            @click="
              () => {
                if (stage >= 2) dialog_save = true;
              }
            "
            v-if="stage >= 2"
          >
            <v-icon size="20">mdi-content-save-outline</v-icon>
          </TextButton>
          <TextButton icon class="ml-4" color="#414249" @click="leave">
            <v-icon>$close</v-icon>
          </TextButton>
        </div>
        <v-sheet
          class="
          center-card
          d-flex
          flex-column
          align-center
        "
        >
          <v-divider class="w-100"></v-divider>
          <span
            v-if="stage > 0"
            class="mt-2 mt-md-4 font-weight-medium medium-font"
            >Категория: Зеленые насаждения</span
          >
          <span v-if="stage > 1" class="mt-2 font-weight-medium medium-font"
            >Тип:
            {{ product.objectType ? product.objectType.title : "?????" }}</span
          >
<!--          <div-->
<!--            v-if="stage > 2 && product.media.length > 0"-->
<!--            class="d-flex mt-2 overflow-x-auto overflow-y-hidden justify-center w-100 pa-0"-->
<!--          >-->
<!--            <div-->
<!--              class="upload-thumbnail"-->
<!--              v-for="file in product.media"-->
<!--              :key="file.seqId"-->
<!--            >-->
<!--              <img @click="openGallery(file.id)" :src="file.url" />-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="stage > 2" class="overflow-x-auto mx-n4" style="direction: rtl; width: calc(100% + 32px)">-->
<!--            <div style="max-width: 1000px; width: max-content; padding: 0 16px;">-->
<!--              <MediaUploadGallery style="direction: ltr; width: max-content; margin: auto" childClass="col-auto gallery-element" :value="product.media" />-->
<!--            </div>-->
<!--          </div>-->
          <div v-if="stage > 2" style="width: calc(100% + 32px); margin: 12px -16px;">
            <MediaUploadGallery ref="fileUpload" :parentClass="mediaUploadGalleryClasses.parent" :childClass="mediaUploadGalleryClasses.child" :value="product.media" />
          </div>
          <span
            v-if="stage > 3"
            class="mt-2 font-weight-medium medium-font text-center"
          >
            <template v-if="product.location">
              <v-icon>mdi-map-marker-outline</v-icon> {{ product.location }}
            </template>
            <template v-else>Объект на карте</template>
          </span>
          <span v-if="stage > 3" class="mt-1 text-center body-2">
            <a @click="showMap = true" v-if="!showMap">
              Показать карту
              <v-icon small style="color: inherit">mdi-chevron-down</v-icon>
            </a>
            <a class="ml-4" @click="showMap = false" v-else>
              Скрыть карту
              <v-icon small style="color: inherit">mdi-chevron-up</v-icon>
            </a>
            <v-img
              v-if="showMap"
              class="my-4 grey lighten-2 rounded"
              :src="staticImgSrc"
              :aspect-ratio="400/220"
              :max-width="400"
              width="100vw"
            />
          </span>
          <transition
            name="fade-transition"
            :duration="{ enter: 100, leave: 100 }"
            mode="out-in"
            @enter="afterEnter"
          >
            <add-issue-category-step
              id="stage-0"
              v-if="stage === 0"
              :value="product"
              :stage="stage"
              @update:stage="setStage"
            />
            <add-issue-topic-step
              id="stage-1"
              v-if="stage === 1"
              :value="product"
              :stage="stage"
              @update:stage="setStage"
            />
            <add-issue-media-step
              id="stage-2"
              v-if="stage === 2"
              :value="product"
              :stage="stage"
              ref="fileUpload2"
              @update:stage="setStage"
              @unlock="unlockProceed"
            />
            <add-issue-map-step
              id="stage-3"
              v-if="stage === 3"
              :value="product"
              @unlock="unlockProceed"
            />
            <add-issue-description-step
              ref="lastStep"
              id="stage-4"
              v-if="stage === 4"
              :stage="stage"
              :value="product"
              @update:import="dialog_import = $event"
              @update:stage="setStage"
              @unlock="unlockProceed"
            />
          </transition>
          <v-alert v-if="anyFileUploadErrors" class="w-100 rounded-lg my-3" type="error">
            <div class="">
              Возникли ошибки при загрузке файлов
              <v-btn depressed light @click="retryFileUpload" class="ml-3 float-end">Попробовать снова</v-btn>
            </div>
          </v-alert>
          <!-- <v-spacer></v-spacer> -->
        </v-sheet>
        <v-spacer></v-spacer>
      </div>
      <div
        class="center-container d-flex flex-column justify-center"
        key="unique"
        v-if="newObjId"
      >
        <v-sheet
          class="
          center-carsd
          my-0 my-md-5
          d-flex
          flex-column
          align-center
        "
        >
          <span class="text-h6 text-center font-weight-bold"
            >Объект #{{ newObjId }} был добавлен на карту.</span
          >
          <div class="d-flex mt-10 mb-2 mb-md-10">
            <v-btn
              class="mr-1 mx-md-2"
              outlined
              color="secondary"
              :min-width="$vuetify.breakpoint.mdAndUp ? '200' : '140'"
              :min-height="$vuetify.breakpoint.mdAndUp ? '48' : '40'"
              @click="reset"
              >Добавить еще</v-btn
            >
            <v-btn
              class="ml-1 mx-md-2"
              color="primary"
              depressed
              :min-width="$vuetify.breakpoint.mdAndUp ? '200' : '140'"
              :min-height="$vuetify.breakpoint.mdAndUp ? '48' : '40'"
              to="/service/objects"
              >К списку объектов</v-btn
            >
          </div>
        </v-sheet>
      </div>
    </transition>
    <div class="action-row action-row--shadow d-flex justify-center px-4 my-3 my-md-5 py-3 background mx-n4" v-if="!newObjId">
      <v-btn
          v-if="stage !== 0"
          @click="setStage(stage - 1)"
          class="mr-1 mx-md-2"
          outlined
          color=""
          :min-width="$vuetify.breakpoint.mdAndUp ? '200' : '140'"
          :min-height="$vuetify.breakpoint.mdAndUp ? '48' : '40'"
      >Назад</v-btn
      >
      <v-btn
          :loading="loading"
          :disabled="!allowedToProceed"
          v-if="stage >= 2"
          @click="setStage(stage + 1)"
          class="ml-1 mx-md-2"
          color="primary"
          depressed
          :min-width="$vuetify.breakpoint.mdAndUp ? '200' : '140'"
          :min-height="$vuetify.breakpoint.mdAndUp ? '48' : '40'"
          v-text="nextStepText"
      ></v-btn>
    </div>
    <leave-draft-dialog v-model="dialog_leave" @exit="exit" />
    <save-draft-dialog :object="product" v-model="dialog_save" @exit="exit" />
    <template-picker-dialog :object="product" v-model="dialog_import" />
  </div>
</template>

<script>
import TextButton from "./TextButton.vue";
import AddIssueCategoryStep from "./NewObjectCategoryStep.vue";
import AddIssueTopicStep from "./NewObjectTypeStep.vue";
import LeaveDraftDialog from "./LeaveDraftDialog.vue";
import AddIssueMediaStep from "./NewObjectMediaStep.vue";
import AddIssueMapStep from "./NewObjectMapStep.vue";
import AddIssueDescriptionStep from "./NewObjectParamsStep.vue";

import EmptyFrame from "../../../components/EmptyFrame.vue";

import opencityService from "@/service/opencity";

import PhotoSwipe from "photoswipe";
import "photoswipe/dist/photoswipe.css";

import categories from "@/assets/categories";
import MediaUploadGallery from "@/views/Objects/NewObject/MediaUploadGallery.vue";
import SaveDraftDialog from "@/views/Objects/NewObject/SaveDraftDialog.vue";
import TemplatePickerDialog from "@/views/Objects/NewObject/TemplatePickerDialog.vue";

export default {
  name: "HelloWorld",
  data: () => ({
    product: null,
    dialog_leave: false,
    dialog_save: false,
    dialog_import: false,
    stage: 0,
    lockProceed: false,
    loading: false,
    newObjId: null,
    showMap: false
  }),
  methods: {
    retryFileUpload() {
      let fu = this.$refs?.fileUpload;
      if (!fu) fu = this.$refs.fileUpload2?.$refs?.input;
      fu.retryAll();
    },
    setStage(stage) {
      if (stage === 5) {
        this.sendObject();
      } else {
        this.stage = stage;
        this.lockProceed = false;
      }
    },
    sendObject() {
      if (this.loading) return;
      this.loading = true;
      this.product.media = this.product.media.map(el => ({ id: el.realId }));
      opencityService
        .postObject(this.product)
        .then(id => {
          if (id != null && id != "") this.newObjId = id;
        })
        .finally(() => (this.loading = false));
    },
    unlockProceed(val) {
      this.lockProceed = !val;
    },
    afterEnter() {
      this.$nextTick(() => {
        this.$vuetify.goTo("#stage-" + this.stage, { offset: 12 });
      });
    },
    leave() {
      if (this.stage >= 2) this.dialog_leave = true;
      else this.exit();
    },
    exit() {
      if (window.history.length === 0) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.back();
      }
    },
    reset() {
      this.setStage(this.product?.objectType ? 2 : 0);
      this.newObjId = null;
      this.product = {
        location: "",
        geometry: null,
        data: {},
        media: [],
        note: "",
        objectType: this.product?.objectType ?? null,
        visible: true
      };
    }
  },
  computed: {
    mediaUploadGalleryClasses() {
      let mobileBig = this.product.media.length > 3;
      let big = this.product.media.length > 5;
      const child = "col ma-0 col-3 pa-1 col-sm-2 pa-sm-2";
      if (big) {
        return {
          parent: "row",
          child
        };
      }
      if (mobileBig) {
        return {
          parent: "justify-sm-center row",
          child
        };
      }

      return {
        parent: "justify-center row",
        child
      };
    },
    allowedToProceed() {
      if (this.stage === 4) {
        return (
          !this.product?.media?.some(el => el.uploading || el.error) &&
          !this.lockProceed
        );
      }
      return !this.lockProceed;
    },
    anyFileUploadErrors() {
      return this.product?.media?.some(el => el.error);
    },
    staticImgSrc() {
      return opencityService.getStaticMapFeatureURL(this.product, 800, 440);
    },
    nextStepText() {
      const { stage } = this;
      if (stage === 4) {
        return "Отправить";
      } else {
        return "Далее";
      }
    }
  },
  created() {
    this.$emit("update:layout", EmptyFrame);
    this.reset();
    if (this.$route.query.type === "1") {
      this.product.objectType = categories[0].topics[0];
      this.setStage(2);
    }
  },
  components: {
    TemplatePickerDialog,
    SaveDraftDialog,
    MediaUploadGallery,
    TextButton,
    AddIssueCategoryStep,
    AddIssueTopicStep,
    LeaveDraftDialog,
    AddIssueMediaStep,
    AddIssueMapStep,
    AddIssueDescriptionStep
  }
};
</script>

<style lang="scss">
.gallery-element {
  width: 120px;
  @media (min-width: 920px) {
    width: 140px;
  }
}

.text-h5 {
  text-align: center;
}

.v-text-field__details {
  padding: 4px 0 !important;
}

.w-100 {
  width: 100%;
}

.v-dialog.dialog {
  border-radius: 0;
  max-width: 624px;
  width: 100%;
}

.v-btn {
  // text-transform: none;
  letter-spacing: normal;
}

.v-tab {
  // text-transform: none;
  letter-spacing: normal;
}

.v-text-field {
  // border-radius: 0 !important;
}

.v-input fieldset {
  // border-width: 2px !important;
}

.v-btn--outlined {
  // border-width: 2px !important;
}

.warning-block {
  position: relative;
  width: 100%;
  z-index: 0;
  font-size: 12px;
  line-height: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #955205;

  // margin: 16px 0;
  @media (min-width: 960px) {
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    // margin: 24px 0 16px 0;
  }
  &:before {
    z-index: -1;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--v-warning-base);
    opacity: 0.15;
    position: absolute;
  }
}

.medium-font {
  line-height: 18px;
  font-size: 15px;
  @media (min-width: 960px) {
    line-height: 24px;
    font-size: 18px;
  }
}

.large-font {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0em;
  @media (min-width: 960px) {
    font-size: 24px;
    line-height: 32px;
  }
}
</style>

<style scoped lang="scss">
.v-input--is-label-active .private-checkbox-label {
  opacity: 1;
}

.private-checkbox-label {
  opacity: 0.7;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) opacity;
  &:hover {
    opacity: 1;
  }
}

.background {

}

.map-mock {
  // background-image: url("../assets/map.png");
  background-size: cover;
  height: 400px;
}

//.demo-scroll {
//   position: sticky;
//   top: 0;
//   background: #ececed;
//   z-index: 1;
//}

h3 {
  margin: 40px 0 0;
}

.center-container {
  max-width: 720px;
  margin: auto;
  width: 100%;
  min-height: 100vh;
  .center-card {
    // padding: 24px 16px;
    min-height: 100%;
    @media (min-width: 960px) {
      // padding: 56px 110px 40px 110px;
    }
  }
}

.demo-issue-stepper {
  // background: #ececed;
  min-height: 100vh;
  width: 100%;
  padding: 0 16px;
  background-color: white;
}

.theme--dark .demo-issue-stepper {
  background-color: #1e1e1e;
}

.upload-thumbnail {
  width: 144px;
  height: 144px;
  margin: 0px 6px;
  cursor: zoom-in;
  flex: 0 0 auto;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.background {
  background: white;
}

.theme--dark .background {
  background: #1e1e1e;
}

.action-row {
  position: sticky;
  //border-radius: 12px 12px 0 0;
  bottom: 0;
  //border-top: thin solid currentColor;
  z-index: 100;
}

.action-row--shadow {
  //border-top: 1px solid;
  //border-image: linear-gradient(
  //    to left,
  //    rgba(0, 0, 0, 0),
  //        rgba(0, 0, 0, 0),
  //    currentColor,
  //        rgba(0, 0, 0, 0),
  //
  //    rgba(0, 0, 0, 0)
  //  )
  //  1;
}

//::v-deep .gallery-grid {
//  display: grid;
//  justify-content: center;
//  grid-template-columns: repeat(6, calc(16.6% - 13.3px));
//  grid-gap: 16px;
//}
</style>
