import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"content-class":"dialog","width":"400"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c(VSheet,{attrs:{"tile":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"px-4 px-md-6 pb-4 pt-4 text-h5 text-center font-weight-bold"},[_vm._v(" Выбрать шаблон ")]),_c(VSpacer),_c(VBtn,{staticClass:"ma-4 mb-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c(VIcon,{attrs:{"size":"24"}},[_vm._v("$close")])],1)],1),_c(VDivider),_c('div',{staticClass:"px-2 overflow-y-auto d-flex flex-column",staticStyle:{"height":"min(380px, 70vh)"}},[_c(VList,{attrs:{"dense":""}},[_vm._l((_vm.templates),function(template){return _c(VListItem,{key:template.id,on:{"click":function($event){return _vm.selectAndExit(template)}}},[_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"-webkit-line-clamp":"2","-webkit-box-orient":"vertical","display":"-webkit-box","white-space":"initial"}},[_vm._v(_vm._s(template.title))])],1),_c(VListItemAction,[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.deleteTemplate(template)}}},[_c(VListItemTitle,[_vm._v("Удалить")])],1)],1)],1)],1)],1)}),(!_vm.loading && _vm.templates.length === 0)?_c('span',{staticClass:"text-center d-block w-100"},[_vm._v("Нет шаблонов")]):_vm._e()],2),_c(VSpacer),_c(VList,{staticStyle:{"position":"sticky","bottom":"0","justify-self":"end"},attrs:{"dense":""}},[_c(VDivider),_c(VListItem,[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-1 mb-0"},[_vm._v("mdi-import")]),_vm._v(" Скопировать из объекта ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }